<template>
  <div class='asset-sidebar__group' @keydown.esc="close">
    <flash-message></flash-message>
    <header>
      <h2><i class='fas fa-link icon'></i>Related Items</h2>
      <a class='button button--success button--small'  v-show='!add && can' href='#' @click.prevent.stop="startAdd">
        <i class='fas fa-plus'></i>New
      </a>
      <a href='#' v-show='add' @click.prevent.stop="add = false" class='button button--secondary button--small'>
        <i class='fas fa-times'></i>Close
      </a>
    </header>
    <div class='relater__toolbar' v-show='add'>
      <div class='search search--hitcher'>
        <icon></icon>
        <input ref='searchInput' id="relaterSearchInput" type='text'
          @click="showSearch = true"
          @input="onInput"
          @keydown.esc="showSearch = false"
          v-model='q' placeholder="Search to add...">
      </div>
      <select @change.prevent.stop="onInput" v-model='isType'>
        <option value='Company'>{{structure_name}}</option>
        <option value='Asset'>Custom Asset</option>
        <option>Article</option>
        <option>Password</option>
        <option>Website</option>
      </select>
      <label v-if='company_id && (isType != "Company")'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Limit to&nbsp;<b>{{company_name}}</b></label>
          <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
    </div>
    <div class='relater__results' v-show='add'>
      <div class='relater__result' v-for='result in results'>
        <div class='button-group'>
          <a href='#' class='button button--small button--success' @click.prevent.stop="addRelation(result.id, result.object_type)">
            <i style='margin: 0;' class='fas fa-plus'></i>
          </a>
          <a :href='result.url' target="_blank" class='button button--small button--secondary'><i style='margin: 0;' class='fas fa-external-link-alt'></i></a>
        </div>
        <p>{{result.name}}<small v-if='result.asset_type'>{{result.asset_type}} / {{result.company_name}}</small></p>
      </div>
    </div>
    <div class='asset-sidebar__box'>
      <template v-for='r in relations' v-if='r.items.length > 0'>
        <header><h1><i :class='r.icon'></i>{{r.name}}</h1></header>
        <section v-for="(relation, index) in r.items">
          <template v-if='isEdit && (editIndex === relation.id)'>
            <h3><a>{{relation.name}}</a></h3>
            <form @submit.prevent.stop='updateDescription(relation.id)' class='asset-sidebar__form asset-sidebar__form--full'>
              <input type='text' v-model='newDescription' :id='"relaterDescInput" + relation.id' placeholder="Describe relation...">
              <div class='button-group'>
                <a href='#' @click.prevent.stop='updateDescription(relation.id)' class='button button--small button--primary'>Save</a>
                <a href='#' @click.prevent.stop='resetEdit' class='button button--small button--secondary'>Cancel</a>
              </div>
            </form>
          </template>

          <template v-if='editIndex != relation.id'>
            <h3>
              <a :href='relation.url'>{{relation.name}} <i v-show='relation.discarded_at' title='Archived' style='opacity: .5' class='fas fa-university'></i>
              <div v-if='relation.description' class='asset-sidebar__box__description'>{{relation.description}}</div>
              </a>
            </h3>
            <div v-if='can && relation.id' class='button-group'>
              <a href='' @click.prevent.stop='startEdit(relation.id, relation.description)' class='button button--small button--secondary'><i class="fas fa-edit"></i></a>
              <a href='#' @click.prevent="removeRelation(relation.id, index)" class='button button--danger button--small'><i class='fas fa-trash'></i></a>
            </div>
          </template>
        </section>
      </template>
      <slot></slot>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Icon from "../components/icon.vue";

export default {
  name: 'relater',
  props: ["id", "type", "can", "company_name", "company_id", "default", "structure_name"],
  components: {
    Icon
  },
  mounted () {
    var that = this;
    that.getRelations()
  },
  methods: {
    getRelations () {
      var that = this;
      that.$api.get(`/relations/get_relations?id=${that.id}&type=${that.type}`)
      .then(function(response) {
        that.relations = response.data
      }).catch(error => {
        console.log(error)
        alert("Something went wrong!")
      })
    },
    startAdd () {
      this.add = true;
      this.resetEdit()
      this.$nextTick(() => document.querySelector("#relaterSearchInput").focus())
    },
    startEdit (id, description) {
      this.isEdit = true;
      this.editIndex = id;
      this.newDescription = description;

      this.$nextTick(() => document.querySelector("#relaterDescInput" + id).focus())
    },
    updateDescription (id) {
      var that = this;
      that.$api.put(`/relations/${id}`, {relation: {description: that.newDescription}}).then(function (response) {
        that.resetEdit()
        that.$api.get(`/relations/get_relations?id=${that.id}&type=${that.type}`)
        .then(function(response) {
          that.getRelations()
        }).catch(error => {
          console.log(error)
          alert("Something went wrong!")
        })
        that.flash("Relation Updated!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        console.log(error)
      })
    },
    removeRelation (id) {
      var that = this;
      if(confirm('Are you sure you want to delete this relation?')) {
        that.$api.delete(`/relations/${id}`).then(function (response) {
          that.getRelations()
          that.resetEdit()
          that.flash("Relation Removed!", 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {
          console.log(error)
          alert("Something went wrong!")
        })
      }
    },
    onInput () {

      var that = this;

      axios.get('/searches', {
        params: {
          q: that.q,
          type: that.isType,
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          disable_type: that.type,
          disable_id: that.id
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    },
    addRelation (id,type) {
      var that = this;
      that.$api.post('/relations', {
        relation: {
          fromable_type: that.type,
          fromable_id: that.id,
          toable_type: type,
          toable_id: id
        }
      })
      .then(function (response) {
        that.getRelations()
        that.showSearch = false;
        that.flash("Relation Added!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        console.log(error)
        alert("Something went wrong!")
      })
    },
    resetEdit () {
      this.isEdit = false;
      this.editIndex = -1;
      this.newDescription = ""
    },
  },
  data: function () {
    return {
      relations: [],
      q: "",
      isType: this.default,
      results: [],
      add: false,
      only_company: true,
      showSearch: false,
      newDescription: "",
      include_archived: false,
      isEdit: false,
      editIndex: -1
    }
  }
}
</script>