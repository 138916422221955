 /* Import TinyMCE */
 import tinymce from 'tinymce';

 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';
/* Import plugins */
import "tinymce/plugins/autolink"
import "tinymce/plugins/link"
import "tinymce/plugins/code"
import "tinymce/plugins/charmap"
import "tinymce/plugins/media"
import "tinymce/plugins/image"
import "tinymce/plugins/hr"
import "tinymce/plugins/imagetools"
import "tinymce/plugins/table"
import "tinymce/plugins/advlist"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/lists"
import "tinymce/plugins/paste"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/codesample"
import "tinymce/plugins/autoresize"
import "tinymce/plugins/autosave"
import "tinymce/plugins/directionality"
import "tinymce/plugins/emoticons"
import "tinymce/plugins/emoticons/js/emojis.js"
import '../lib/powerpaste.min.js';
import '../lib/powerpaste-wordimport.js';

// import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
// import contentCss from 'tinymce/skins/content/default/content.css';

export default function initTiny (that, selector, min_height, dark_mode) {

  tinymce.init({
    body_class: `rich_text_content ${dark_mode ? 'dark-portal' : ''}`,
    menubar: false,
    browser_spellcheck: true,
    contextmenu: false,
    selector: selector,
    codesample_global_prismjs: true,
    codesample_languages: [
      { text: 'HTML/XML', value: 'markup' },
      { text: 'JavaScript', value: 'javascript' },
      { text: 'CSS', value: 'css' },
      { text: 'Powershell', value: 'powershell' },
      { text: 'PHP', value: 'php' },
      { text: 'Ruby', value: 'ruby' },
      { text: 'Python', value: 'python' },
      { text: 'Java', value: 'java' },
      { text: 'C', value: 'c' },
      { text: 'C#', value: 'csharp' },
      { text: 'C++', value: 'cpp' },
      { text: 'SQL', value: 'sql' },
      { text: 'Swift', value: 'swift' },
      { text: 'Markdown', value: 'markdown' },
      { text: 'Bash', value: 'bash' },
      { text: 'JSON', value: 'json' },
      { text: 'TOML', value: 'toml' }
    ],
    auto_focus : min_height ? "mytextarea" : false,
    // skin: false,
    resize: "both",
    skin: false,
    content_css: [
      document.querySelector("#main_application_stylesheet").href
    ],
    content_style: document.querySelector("#mainApplicationStylesForColors").innerHTML,
    relative_urls: false,
    valid_children: "-div[p|h1|h2|h3|h4|h5|h6|blockquote],+div[pre],+div[img]",
    automatic_uploads: true,
    image_title: true,
    image_advtab: true,
    target_list: [
      {title: 'Same tab', value: ''},
      {title: 'New tab', value: '_blank'}
    ],
    // min_height: min_height ? min_height : false,
    paste_data_images: true,
    autosave_ask_before_unload: min_height ? true : false,
    style_formats: [
          {title: "Header Large", format: "h1", styles: {color: '#ff0000'}},
          {title: "Header Medium", format: "h2"},
          {title: "Header Small", format: "h3"},
          {title: "Header Tiny", format: "h4"},
          {title: "Paragraph", format: "p", exact: true, classes: ''},
          {title: "Blockquote", format: "blockquote"},
          {title: "Inline Code", inline: "code"},
          {title: "Callouts", items: [
                  {title: "Info", format: 'calloutinfo'},
                  {title: "Success", format: 'calloutsuccess'},
                  {title: "Warning", format: 'calloutwarning'},
                  {title: "Danger", format: 'calloutdanger'}
              ]},
      ],
      formats: {
              codeeditor: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div'},
              alignleft: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'align-left'},
              aligncenter: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'align-center'},
              alignright: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'align-right'},
              calloutsuccess: {block: 'p', exact: true, attributes: {class: 'callout callout-success'}},
              calloutinfo: {block: 'p', exact: true, attributes: {class: 'callout callout-info'}},
              calloutwarning: {block: 'p', exact: true, attributes: {class: 'callout callout-warning'}},
              calloutdanger: {block: 'p', exact: true, attributes: {class: 'callout callout-danger'}}
          },
    images_upload_url: '/public_photos?record_type=Article&record_id=' + that.id,
    file_picker_types: 'image',
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
      */

      input.onchange = function () {
        var file = this.files[0];

        var reader = new FileReader();
        reader.onload = function () {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          var id = 'blobid' + (new Date()).getTime();
          var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(',')[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
    init_instance_callback: function(editor) {
      editor.on('Dirty', function(e) {
        that.isDirty = true
      });
      editor.on('change', function(e) {
        if (that.index === undefined) {
          that.$store.dispatch('procedures/updateProcedureDescription', editor.getContent())
        } else {
          that.$store.dispatch('procedures/updateTaskDescription', [that.index, editor.getContent()])
        }
      });
    },
    plugins: [
      'advlist codesample powerpaste autolink lists link image',
      'searchreplace code directionality fullscreen',
      'media table code autosave emoticons charmap hr'
    ],
    toolbar: 'undo redo | styleselect | ' +
      'bold italic underline strikethrough superscript subscript | forecolor backcolor | bullist numlist outdent indent | table link image media blockquote hr | alignleft aligncenter ' +
      'alignright alignjustify | codesample emoticons charmap | ' +
      ' | removeformat | ltr rtl searchreplace code fullscreen',
    emoticons_database: 'emojis',
    branding: false,
    toolbar_sticky: true
  });  
}